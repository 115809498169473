import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _fd99a9a4 = () => interopDefault(import('../pages/accessibility.vue' /* webpackChunkName: "pages/accessibility" */))
const _3cb3b1c1 = () => interopDefault(import('../pages/acerca-de.vue' /* webpackChunkName: "pages/acerca-de" */))
const _646e9bbc = () => interopDefault(import('../pages/aviso-legal.vue' /* webpackChunkName: "pages/aviso-legal" */))
const _47ec685a = () => interopDefault(import('../pages/ayuda.vue' /* webpackChunkName: "pages/ayuda" */))
const _26a16a16 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _d0094c88 = () => interopDefault(import('../pages/cambiar-contrasena.vue' /* webpackChunkName: "pages/cambiar-contrasena" */))
const _2277da19 = () => interopDefault(import('../pages/ccpa-privacy-notice.vue' /* webpackChunkName: "pages/ccpa-privacy-notice" */))
const _1645033e = () => interopDefault(import('../views/StoresBridge.vue' /* webpackChunkName: "" */))
const _65364df4 = () => interopDefault(import('../views/Homepage.vue' /* webpackChunkName: "" */))
const _e8df3f80 = () => interopDefault(import('../pages/compartir/index.vue' /* webpackChunkName: "pages/compartir/index" */))
const _d6dd0fc2 = () => interopDefault(import('../pages/contacto.vue' /* webpackChunkName: "pages/contacto" */))
const _0d6da106 = () => interopDefault(import('../pages/contrasena-actualizada-exitosamente.vue' /* webpackChunkName: "pages/contrasena-actualizada-exitosamente" */))
const _00a30c22 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _213a75ae = () => interopDefault(import('../pages/cupones/index.vue' /* webpackChunkName: "pages/cupones/index" */))
const _dc1b7612 = () => interopDefault(import('../pages/dashboard/index.vue' /* webpackChunkName: "pages/dashboard/index" */))
const _1a1ef63a = () => interopDefault(import('../pages/dashboard/index/index.vue' /* webpackChunkName: "pages/dashboard/index/index" */))
const _a4a02fb6 = () => interopDefault(import('../pages/dashboard/index/actividad.vue' /* webpackChunkName: "pages/dashboard/index/actividad" */))
const _b27bb1ba = () => interopDefault(import('../pages/dashboard/index/ajustes.vue' /* webpackChunkName: "pages/dashboard/index/ajustes" */))
const _a8464dfa = () => interopDefault(import('../pages/dashboard/index/chats.vue' /* webpackChunkName: "pages/dashboard/index/chats" */))
const _5a52cc5a = () => interopDefault(import('../pages/dashboard/index/descuentos.vue' /* webpackChunkName: "pages/dashboard/index/descuentos" */))
const _748d2c58 = () => interopDefault(import('../pages/dashboard/index/guardados.vue' /* webpackChunkName: "pages/dashboard/index/guardados" */))
const _55832a05 = () => interopDefault(import('../pages/dashboard/index/monedas.vue' /* webpackChunkName: "pages/dashboard/index/monedas" */))
const _1a12168b = () => interopDefault(import('../pages/dashboard/index/premios.vue' /* webpackChunkName: "pages/dashboard/index/premios" */))
const _f22668da = () => interopDefault(import('../pages/dashboard/index/sorteos.vue' /* webpackChunkName: "pages/dashboard/index/sorteos" */))
const _7ad0bd4b = () => interopDefault(import('../views/CategoriesBridge.vue' /* webpackChunkName: "" */))
const _5ada1e24 = () => interopDefault(import('../pages/do-not-sell.vue' /* webpackChunkName: "pages/do-not-sell" */))
const _6c515c3e = () => interopDefault(import('../pages/eventos/index.vue' /* webpackChunkName: "pages/eventos/index" */))
const _eed636a6 = () => interopDefault(import('../pages/expirados.vue' /* webpackChunkName: "pages/expirados" */))
const _1db2ea14 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _68eee0e0 = () => interopDefault(import('../pages/foros/index.vue' /* webpackChunkName: "pages/foros/index" */))
const _b8ce4d50 = () => interopDefault(import('../pages/gratis.vue' /* webpackChunkName: "pages/gratis" */))
const _65c53fc3 = () => interopDefault(import('../pages/inicio-sin-contrasena.vue' /* webpackChunkName: "pages/inicio-sin-contrasena" */))
const _0eb9367e = () => interopDefault(import('../pages/interest-driven-deals.vue' /* webpackChunkName: "pages/interest-driven-deals" */))
const _27af06d4 = () => interopDefault(import('../pages/jotadeals-terms-and-policies.vue' /* webpackChunkName: "pages/jotadeals-terms-and-policies" */))
const _d306b9da = () => interopDefault(import('../pages/legal-copyright.vue' /* webpackChunkName: "pages/legal-copyright" */))
const _7e3795ca = () => interopDefault(import('../pages/legales/index.vue' /* webpackChunkName: "pages/legales/index" */))
const _868cc86e = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _f6f60c2c = () => interopDefault(import('../views/InstallExtension.vue' /* webpackChunkName: "" */))
const _47549fde = () => interopDefault(import('../pages/mensajeria/index.vue' /* webpackChunkName: "pages/mensajeria/index" */))
const _49b646cc = () => interopDefault(import('../pages/notificaciones.vue' /* webpackChunkName: "pages/notificaciones" */))
const _225c6968 = () => interopDefault(import('../pages/novedades/index.vue' /* webpackChunkName: "pages/novedades/index" */))
const _3653004c = () => interopDefault(import('../pages/nueva-contrasena.vue' /* webpackChunkName: "pages/nueva-contrasena" */))
const _19c78976 = () => interopDefault(import('../pages/ofertas/index.vue' /* webpackChunkName: "pages/ofertas/index" */))
const _2acfe38b = () => interopDefault(import('../pages/olvide-mi-contrasena.vue' /* webpackChunkName: "pages/olvide-mi-contrasena" */))
const _1526ae9c = () => interopDefault(import('../pages/politicas-de-privacidad.vue' /* webpackChunkName: "pages/politicas-de-privacidad" */))
const _5a6aa462 = () => interopDefault(import('../views/Preferences.vue' /* webpackChunkName: "" */))
const _a7401aee = () => interopDefault(import('../pages/prensa.vue' /* webpackChunkName: "pages/prensa" */))
const _106493fa = () => interopDefault(import('../pages/registro.vue' /* webpackChunkName: "pages/registro" */))
const _b7359262 = () => interopDefault(import('../pages/reglas-de-comunidad.vue' /* webpackChunkName: "pages/reglas-de-comunidad" */))
const _1aebf6f8 = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _61ce03d8 = () => interopDefault(import('../pages/sitemap/index.vue' /* webpackChunkName: "pages/sitemap/index" */))
const _8fe36f4a = () => interopDefault(import('../pages/sitemap/index/index.vue' /* webpackChunkName: "pages/sitemap/index/index" */))
const _beb2c9e4 = () => interopDefault(import('../pages/sitemap/index/promociones/index.vue' /* webpackChunkName: "pages/sitemap/index/promociones/index" */))
const _783399f8 = () => interopDefault(import('../pages/sitemap/index/tiendas/index.vue' /* webpackChunkName: "pages/sitemap/index/tiendas/index" */))
const _c2200c74 = () => interopDefault(import('../pages/sitemap/index/promociones/_slug.vue' /* webpackChunkName: "pages/sitemap/index/promociones/_slug" */))
const _0a834d2a = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _72c40d31 = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _32e8204c = () => interopDefault(import('../pages/verificar-cuenta.vue' /* webpackChunkName: "pages/verificar-cuenta" */))
const _6c2e895a = () => interopDefault(import('../pages/compartir/chat.vue' /* webpackChunkName: "pages/compartir/chat" */))
const _0194f664 = () => interopDefault(import('../pages/compartir/cupon/index.vue' /* webpackChunkName: "pages/compartir/cupon/index" */))
const _52715a27 = () => interopDefault(import('../pages/compartir/finalizado.vue' /* webpackChunkName: "pages/compartir/finalizado" */))
const _0fbe3176 = () => interopDefault(import('../pages/compartir/oferta/index.vue' /* webpackChunkName: "pages/compartir/oferta/index" */))
const _9f18c36c = () => interopDefault(import('../pages/compartir/sorteo.vue' /* webpackChunkName: "pages/compartir/sorteo" */))
const _52ddea03 = () => interopDefault(import('../pages/compartir/cupon/crear.vue' /* webpackChunkName: "pages/compartir/cupon/crear" */))
const _636696ab = () => interopDefault(import('../pages/compartir/oferta/crear.vue' /* webpackChunkName: "pages/compartir/oferta/crear" */))
const _cb3baa52 = () => interopDefault(import('../views/StoreMask.vue' /* webpackChunkName: "" */))
const _5e9d07fa = () => interopDefault(import('../pages/blog/_category/index.vue' /* webpackChunkName: "pages/blog/_category/index" */))
const _daf94caa = () => interopDefault(import('../views/DiscountDetails.vue' /* webpackChunkName: "" */))
const _90d1d662 = () => interopDefault(import('../pages/dashboard/_user.vue' /* webpackChunkName: "pages/dashboard/_user" */))
const _40ee8412 = () => interopDefault(import('../pages/dashboard/_user/index.vue' /* webpackChunkName: "pages/dashboard/_user/index" */))
const _b8566c06 = () => interopDefault(import('../pages/dashboard/_user/actividad.vue' /* webpackChunkName: "pages/dashboard/_user/actividad" */))
const _5aa7324a = () => interopDefault(import('../pages/dashboard/_user/chats.vue' /* webpackChunkName: "pages/dashboard/_user/chats" */))
const _bd641a0a = () => interopDefault(import('../pages/dashboard/_user/descuentos.vue' /* webpackChunkName: "pages/dashboard/_user/descuentos" */))
const _6fbe9ece = () => interopDefault(import('../pages/eventos/_slug.vue' /* webpackChunkName: "pages/eventos/_slug" */))
const _6c5c2370 = () => interopDefault(import('../pages/foros/_slug.vue' /* webpackChunkName: "pages/foros/_slug" */))
const _240af753 = () => interopDefault(import('../views/DiscountMask.vue' /* webpackChunkName: "" */))
const _7c80f482 = () => interopDefault(import('../pages/legales/_slug.vue' /* webpackChunkName: "pages/legales/_slug" */))
const _20a5c820 = () => interopDefault(import('../pages/novedades/_slug.vue' /* webpackChunkName: "pages/novedades/_slug" */))
const _4e11f2d4 = () => interopDefault(import('../pages/blog/_category/_article.vue' /* webpackChunkName: "pages/blog/_category/_article" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/accessibility",
    component: _fd99a9a4,
    name: "accessibility"
  }, {
    path: "/acerca-de",
    component: _3cb3b1c1,
    name: "acerca-de"
  }, {
    path: "/aviso-legal",
    component: _646e9bbc,
    name: "aviso-legal"
  }, {
    path: "/ayuda",
    component: _47ec685a,
    name: "ayuda"
  }, {
    path: "/blog",
    component: _26a16a16,
    name: "blog"
  }, {
    path: "/cambiar-contrasena",
    component: _d0094c88,
    name: "cambiar-contrasena"
  }, {
    path: "/ccpa-privacy-notice",
    component: _2277da19,
    name: "ccpa-privacy-notice"
  }, {
    path: "/codigos-descuento",
    component: _1645033e,
    name: "codigos-descuento"
  }, {
    path: "/comentados",
    component: _65364df4,
    name: "index-comentados"
  }, {
    path: "/compartir",
    component: _e8df3f80,
    name: "compartir"
  }, {
    path: "/contacto",
    component: _d6dd0fc2,
    name: "contacto"
  }, {
    path: "/contrasena-actualizada-exitosamente",
    component: _0d6da106,
    name: "contrasena-actualizada-exitosamente"
  }, {
    path: "/cookies",
    component: _00a30c22,
    name: "cookies"
  }, {
    path: "/cupones",
    component: _213a75ae,
    name: "cupones"
  }, {
    path: "/dashboard",
    component: _dc1b7612,
    children: [{
      path: "",
      component: _1a1ef63a,
      name: "dashboard-index"
    }, {
      path: "actividad",
      component: _a4a02fb6,
      name: "dashboard-index-actividad"
    }, {
      path: "ajustes",
      component: _b27bb1ba,
      name: "dashboard-index-ajustes"
    }, {
      path: "chats",
      component: _a8464dfa,
      name: "dashboard-index-chats"
    }, {
      path: "descuentos",
      component: _5a52cc5a,
      name: "dashboard-index-descuentos"
    }, {
      path: "guardados",
      component: _748d2c58,
      name: "dashboard-index-guardados"
    }, {
      path: "monedas",
      component: _55832a05,
      name: "dashboard-index-monedas"
    }, {
      path: "premios",
      component: _1a12168b,
      name: "dashboard-index-premios"
    }, {
      path: "sorteos",
      component: _f22668da,
      name: "dashboard-index-sorteos"
    }]
  }, {
    path: "/descuentos",
    component: _7ad0bd4b,
    name: "descuentos"
  }, {
    path: "/do-not-sell",
    component: _5ada1e24,
    name: "do-not-sell"
  }, {
    path: "/eventos",
    component: _6c515c3e,
    name: "eventos"
  }, {
    path: "/expirados",
    component: _eed636a6,
    name: "expirados"
  }, {
    path: "/faq",
    component: _1db2ea14,
    name: "faq"
  }, {
    path: "/foros",
    component: _68eee0e0,
    name: "foros"
  }, {
    path: "/gratis",
    component: _b8ce4d50,
    name: "gratis"
  }, {
    path: "/inicio-sin-contrasena",
    component: _65c53fc3,
    name: "inicio-sin-contrasena"
  }, {
    path: "/interest-driven-deals",
    component: _0eb9367e,
    name: "interest-driven-deals"
  }, {
    path: "/jotadeals-terms-and-policies",
    component: _27af06d4,
    name: "jotadeals-terms-and-policies"
  }, {
    path: "/legal-copyright",
    component: _d306b9da,
    name: "legal-copyright"
  }, {
    path: "/legales",
    component: _7e3795ca,
    name: "legales"
  }, {
    path: "/login",
    component: _868cc86e,
    name: "login"
  }, {
    path: "/lp",
    component: _f6f60c2c,
    name: "lp"
  }, {
    path: "/mensajeria",
    component: _47549fde,
    name: "mensajeria"
  }, {
    path: "/notificaciones",
    component: _49b646cc,
    name: "notificaciones"
  }, {
    path: "/novedades",
    component: _225c6968,
    name: "novedades"
  }, {
    path: "/nueva-contrasena",
    component: _3653004c,
    name: "nueva-contrasena"
  }, {
    path: "/nuevos",
    component: _65364df4,
    name: "index-nuevos"
  }, {
    path: "/ofertas",
    component: _19c78976,
    name: "ofertas"
  }, {
    path: "/olvide-mi-contrasena",
    component: _2acfe38b,
    name: "olvide-mi-contrasena"
  }, {
    path: "/politicas-de-privacidad",
    component: _1526ae9c,
    name: "politicas-de-privacidad"
  }, {
    path: "/preferencias",
    component: _5a6aa462,
    name: "preferences"
  }, {
    path: "/prensa",
    component: _a7401aee,
    name: "prensa"
  }, {
    path: "/registro",
    component: _106493fa,
    name: "registro"
  }, {
    path: "/reglas-de-comunidad",
    component: _b7359262,
    name: "reglas-de-comunidad"
  }, {
    path: "/search",
    component: _1aebf6f8,
    name: "search"
  }, {
    path: "/sitemap",
    component: _61ce03d8,
    children: [{
      path: "",
      component: _8fe36f4a,
      name: "sitemap-index"
    }, {
      path: "promociones",
      component: _beb2c9e4,
      name: "sitemap-index-promociones"
    }, {
      path: "tiendas",
      component: _783399f8,
      name: "sitemap-index-tiendas"
    }, {
      path: "promociones/:slug?",
      component: _c2200c74,
      name: "sitemap-index-promociones-slug"
    }]
  }, {
    path: "/subscribe",
    component: _0a834d2a,
    name: "subscribe"
  }, {
    path: "/unsubscribe",
    component: _72c40d31,
    name: "unsubscribe"
  }, {
    path: "/verificar-cuenta",
    component: _32e8204c,
    name: "verificar-cuenta"
  }, {
    path: "/compartir/chat",
    component: _6c2e895a,
    name: "compartir-chat"
  }, {
    path: "/compartir/cupon",
    component: _0194f664,
    name: "compartir-cupon"
  }, {
    path: "/compartir/finalizado",
    component: _52715a27,
    name: "compartir-finalizado"
  }, {
    path: "/compartir/oferta",
    component: _0fbe3176,
    name: "compartir-oferta"
  }, {
    path: "/compartir/sorteo",
    component: _9f18c36c,
    name: "compartir-sorteo"
  }, {
    path: "/compartir/cupon/crear",
    component: _52ddea03,
    name: "compartir-cupon-crear"
  }, {
    path: "/compartir/oferta/crear",
    component: _636696ab,
    name: "compartir-oferta-crear"
  }, {
    path: "/codigos-descuento/letra/:slug",
    component: _1645033e,
    name: "codigos-descuento-letra-slug"
  }, {
    path: "/descuentos/buscar/:slug",
    component: _7ad0bd4b,
    name: "descuentos-buscar-slug"
  }, {
    path: "/go/codigos-descuento/:id",
    component: _cb3baa52,
    name: "go-store-slug"
  }, {
    path: "/blog/:category",
    component: _5e9d07fa,
    name: "blog-category"
  }, {
    path: "/codigos-descuento/:slug",
    component: _1645033e,
    name: "codigos-descuento-slug"
  }, {
    path: "/cupones/:slug",
    component: _daf94caa,
    name: "cupones-slug"
  }, {
    path: "/dashboard/:user",
    component: _90d1d662,
    children: [{
      path: "",
      component: _40ee8412,
      name: "dashboard-user"
    }, {
      path: "actividad",
      component: _b8566c06,
      name: "dashboard-user-actividad"
    }, {
      path: "chats",
      component: _5aa7324a,
      name: "dashboard-user-chats"
    }, {
      path: "descuentos",
      component: _bd641a0a,
      name: "dashboard-user-descuentos"
    }]
  }, {
    path: "/descuentos/:slug",
    component: _7ad0bd4b,
    name: "descuentos-slug"
  }, {
    path: "/eventos/:slug",
    component: _6fbe9ece,
    name: "eventos-slug"
  }, {
    path: "/foros/:slug",
    component: _6c5c2370,
    name: "foros-slug"
  }, {
    path: "/go/:id",
    component: _240af753,
    name: "go-id"
  }, {
    path: "/goprize/:hash",
    component: _240af753,
    name: "goprize-hash"
  }, {
    path: "/legales/:slug",
    component: _7c80f482,
    name: "legales-slug"
  }, {
    path: "/lp/:slug",
    component: _f6f60c2c,
    name: "lp-slug"
  }, {
    path: "/novedades/:slug",
    component: _20a5c820,
    name: "novedades-slug"
  }, {
    path: "/ofertas/:slug",
    component: _daf94caa,
    name: "ofertas-slug"
  }, {
    path: "/open/:slug",
    component: _240af753,
    name: "open-slug"
  }, {
    path: "/blog/:category/:article",
    component: _4e11f2d4,
    name: "blog-category-article"
  }, {
    path: "/",
    component: _65364df4,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
