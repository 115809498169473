import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_2dfc5096 from 'nuxt_plugin_plugin_2dfc5096' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_sentryserver_12d28070 from 'nuxt_plugin_sentryserver_12d28070' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_a5cde230 from 'nuxt_plugin_sentryclient_a5cde230' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_router_a58016fa from 'nuxt_plugin_router_a58016fa' // Source: ./router.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_2694f454 from 'nuxt_plugin_cookieuniversalnuxt_2694f454' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_clipboard_5fee4be8 from 'nuxt_plugin_clipboard_5fee4be8' // Source: ./clipboard.js (mode: 'client')
import nuxt_plugin_axios_31522cac from 'nuxt_plugin_axios_31522cac' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_axiosinterceptorsinit_c10df5aa from 'nuxt_plugin_axiosinterceptorsinit_c10df5aa' // Source: ../plugins/axios-interceptors-init.ts (mode: 'all')
import nuxt_plugin_apiinit_daa74398 from 'nuxt_plugin_apiinit_daa74398' // Source: ../plugins/api-init.ts (mode: 'all')
import nuxt_plugin_assetsinit_659674c9 from 'nuxt_plugin_assetsinit_659674c9' // Source: ../plugins/assets-init.ts (mode: 'all')
import nuxt_plugin_utilsinit_48b6396a from 'nuxt_plugin_utilsinit_48b6396a' // Source: ../plugins/utils-init.ts (mode: 'all')
import nuxt_plugin_serverinit_32f07089 from 'nuxt_plugin_serverinit_32f07089' // Source: ../plugins/server-init.ts (mode: 'all')
import nuxt_plugin_clientinit_31582d11 from 'nuxt_plugin_clientinit_31582d11' // Source: ../plugins/client-init.ts (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"Bchollos","htmlAttrs":{"lang":"es-ES"},"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"sharesales","content":"GNVBNDMX183803279"},{"name":"impact-site-verification","content":"b9c38ce6-ac32-4915-8af3-6dc8d1923527"},{"name":"google-site-verification","content":"bi9t1osp8emNaj7rz1jHW-Ur0ObgTMPMn6PZ3F7aHzs"},{"name":"ir-site-verification-token","content":"-378293540"},{"name":"verify-admitad","content":"87c5608d9e"},{"hid":"og:image","property":"og:image","content":"\u002Fassets\u002Fjpg\u002Fbchollos\u002Fpreview.jpg"},{"hid":"og:image:secure_url","property":"og:image:secure_url","content":"\u002Fassets\u002Fjpg\u002Fbchollos\u002Fpreview.jpg"},{"hid":"twitter:image","property":"twitter:image","content":"\u002Fassets\u002Fjpg\u002Fbchollos\u002Fpreview.jpg"},{"hid":"og:site_name","property":"og:site_name","content":"Bchollos"},{"hid":"og:type","property":"og:type","content":"website"},{"hid":"og:locale","property":"og:locale","content":"es-ES"},{"hid":"twitter:card","property":"twitter:card","content":"summary"}],"script":[{"hid":"fa-head","type":"text\u002Fjavascript","async":true,"defer":true,"innerHTML":"setTimeout(function() {\n    var script = document.createElement(\"script\")\n    script.type = \"text\u002Fjavascript\"\n    script.src = \"\u002Fjs\u002Ffontawesome.js\"\n    script.async = true\n    script.defer = true\n    document.head.append(script)\n                }, 6000)"},{"src":"https:\u002F\u002Fconsent.cookiebot.com\u002Fuc.js?cbid=1e3472d7-1c4c-4b8d-800b-0204441d4713","type":"text\u002Fjavascript"}],"__dangerouslyDisableSanitizersByTagID":{"fa-head":["innerHTML"],"digidip-head":["innerHTML"],"gtm-body":["innerHTML"]},"link":[],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_2dfc5096 === 'function') {
    await nuxt_plugin_plugin_2dfc5096(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_12d28070 === 'function') {
    await nuxt_plugin_sentryserver_12d28070(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_a5cde230 === 'function') {
    await nuxt_plugin_sentryclient_a5cde230(app.context, inject)
  }

  if (typeof nuxt_plugin_router_a58016fa === 'function') {
    await nuxt_plugin_router_a58016fa(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_2694f454 === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_2694f454(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clipboard_5fee4be8 === 'function') {
    await nuxt_plugin_clipboard_5fee4be8(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_31522cac === 'function') {
    await nuxt_plugin_axios_31522cac(app.context, inject)
  }

  if (typeof nuxt_plugin_axiosinterceptorsinit_c10df5aa === 'function') {
    await nuxt_plugin_axiosinterceptorsinit_c10df5aa(app.context, inject)
  }

  if (typeof nuxt_plugin_apiinit_daa74398 === 'function') {
    await nuxt_plugin_apiinit_daa74398(app.context, inject)
  }

  if (typeof nuxt_plugin_assetsinit_659674c9 === 'function') {
    await nuxt_plugin_assetsinit_659674c9(app.context, inject)
  }

  if (typeof nuxt_plugin_utilsinit_48b6396a === 'function') {
    await nuxt_plugin_utilsinit_48b6396a(app.context, inject)
  }

  if (typeof nuxt_plugin_serverinit_32f07089 === 'function') {
    await nuxt_plugin_serverinit_32f07089(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clientinit_31582d11 === 'function') {
    await nuxt_plugin_clientinit_31582d11(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
